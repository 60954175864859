import React, { useEffect } from "react";
import "../../css/bookings.css";
import Footer from "../Footer";
import uparrowIcon from "../../asserts/svgs/uparrow.svg";
import saas from "../../asserts/svgs/saas 1.svg";
import frame from "../../asserts/svgs/Frame 2608707.svg";
import frameTwo from "../../asserts/svgs/Frame 2608782.svg";
import icon from "../../asserts/svgs/icon.svg";
import group from "../../asserts/svgs/Group 159.svg";
import bookingsHeader from "../../asserts/Images/bookings-header.png";
import travelSaas from "../../asserts/Images/bookings-travel-saas.png";
import bookingDigital from "../../asserts/Images/bookings-digital.png";
import bookingFulfilment from "../../asserts/Images/bookings-fulfilment.png";
import bookingsAirline from "../../asserts/Images/bookings-airline.png";
import { Link } from 'react-router-dom';

const Bookings = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="background-bookings">
                <div className="" style={{maxWidth:"1440px", margin:"0 auto"}}>
                <div className="breadcrumb">
                    <ul>
                        <li><Link to="/" style={{ textDecoration: "none", color: "white" }}>Home</Link></li>
                        <li>Transformational Booking & Fulfilment Platforms</li>
                    </ul>
                </div>
                <div className="content mobile_vesrion">
                    <h1 className="heading-title">Transformational Booking <br />& Fulfilment Platforms</h1>
                    <p className="paragraph mobileV">
                        zen3 offers solutions for the provision of Transformational Booking & Fulfilment Platforms. All solutions incorporate the zen3’s proprietary Travel SaaS (Travel Software as a Solution) platform.<br /><br />

                        Travel SaaS enables clients to lower cost of distribution and fulfilment across the travel ecosystem enabling content solutions for Airlines to distribute direct to customers or through 3rd party intermediaries.<br /><br />

                        The solutions deliver new generation outcomes for intermediaries such as Travel Agents, OTA’s, TMC’s, Tour Operators, Wholesalers by offering a set of processes enabling access to all forms of air travel content – NDC, API’s, Direct to PSS and where necessary legacy EDIFACT content. Our approach is to enable Airlines to minimise cost of distribution, including direct ticketing whilst enabling intermediaries to access full content and to service client bookings efficiently and at minimised total cost of ownership. Our airline direct (airline holidays) product facilitates higher ratios of non-airline ancillary sales and revenues via the latest best practices in Dynamic Packaging.
                    </p>
                    <div className="image-container mobileT">
                        <img src={bookingsHeader} alt="Description of the image" width={700} height={400} />
                    </div>
                </div>
                </div> 
            </div>
            <div className="main-container">
                <div className="title-container">
                    <h2 className="title-text">zen3 products & services all include the benefits of our proprietary “Travel SaaS” platform</h2>
                </div>
                <div className="horizontal-view">
                    <div className="horizontal-item">
                        <img src={travelSaas} alt="Description of the image" width={688} height={668} />
                    </div>
                    <div className="horizontal-item">
                        <div className="travel_saas_img">
                            <img src={saas} width={56} height={56} />
                            <h3 className="horizontal-title space-bt">Travel SaaS</h3></div>
                        <div className="horizontal-paragraph">
                            <img src={frame} />
                            <p className="horizontal-content">
                                zen3’s proprietary Travel SaaS platform underpins our solutions in the design, delivery, and operation of digitally transformative booking & fulfillment platforms which enable zen3 clients to lower cost of distribution and fulfilment across the travel ecosystem.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="card" style={{ marginBottom: "48px" }}>
                    <div className="cards">
                        <div className="card-title titles">
                            <div className="title-name">Content solutions</div>
                        </div>
                        <div className="card-content">
                            We offer content solutions for Airlines to distribute direct to customers or through 3rd Party intermediaries. These solutions also deliver the latest generation capability for intermediaries such as
                        </div>
                        <div className="available-platform">
                            <div className="text-tabs avail-color">
                                <span className="tabs-button" style={{ color: "#FFFBFF" }}>Travel Agents</span>
                            </div>
                            <div className="text-tabs avail-color">
                                <span className="tabs-button" style={{ color: "#FFFBFF" }}>OTA’s</span>
                            </div>
                            <div className="text-tabs avail-color">
                                <span className="tabs-button" style={{ color: "#FFFBFF" }}>TMC’s</span>
                            </div>
                            <div className="text-tabs avail-color">
                                <span className="tabs-button" style={{ color: "#FFFBFF" }}>Tour Operators / Wholesalers.</span>
                            </div>
                        </div>
                        <div className="card-content">
                            by offering a set of processes enabling access to all forms of air travel content
                        </div>
                        <div className="available-platform">
                            <div className="text-tabs">
                                <span className="tabs-button">NDC</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">API’s</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Direct to PSS</span>
                            </div>
                        </div>
                        <div className="card-content">
                            and where necessary,
                        </div>
                        <div className="available-platform">
                            <div className="text-tabs">
                                <span className="tabs-button">legacy EDIFACT content</span>
                            </div>
                        </div>
                    </div>
                    <div className="cards">
                        <div className="card-title titles">
                            <div className="title-name">Cost Optimization</div>
                        </div>
                        <div className="card-content">
                            Our approach is to enable airlines to achieve their goals to minimise cost of distribution / optimise revenue, including direct ticketing whilst enabling intermediaries to access full airline content and to service client bookings efficiently and at minimised total cost of ownership.
                        </div>
                        <div className="card-content">
                            Crucially, the zen3 approach enables optimum use of a mix of
                        </div>
                        <div className="available-platform">
                            <div className="text-tabs">
                                <span className="tabs-button">Airline Direct</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">PSS</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">NDC</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Edifact</span>
                            </div>
                        </div>
                        <div className="card-content">
                            This means optimum efficiency to the lowest total cost of ownership of a future focussed solution.
                        </div>
                    </div>
                    <div className="cards">
                        <div className="card-title titles">
                            <div className="title-name">Revenue Optimization</div>
                        </div>
                        <div className="card-content">
                            Our airline direct (airline holiday) product facilitates higher ratios of non-airline ancillary sales and revenues via the latest best practices in dynamic packaging.
                        </div>
                        <div className="card-content">
                            zen3 offers all customers access to our non-airline revenue content such as
                        </div>
                        <div className="available-platform">
                            <div className="text-tabs">
                                <span className="tabs-button">Hotel</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Car Rental</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Transfers</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Experiences</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Insurance</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Rail travel</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Cruise</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Airport Services</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="horizontal-view mobile_vesrion">
                    <div className="horizontal-item mobileV">
                        <div className="travel_saas_img">
                            <img src={frameTwo} width={56} height={56} />
                            <h3 className="horizontal-title space-bt">Digital Dynamic Packaging</h3></div>
                        <div className="horizontal-paragraph">
                            <img src={frame} />
                            <p className="horizontal-content"> The customer segments these solutions provide include Airlines via digital dynamic packaging (airline holidays) solutions.</p>
                        </div>
                        <div className="horizontal-paragraph">
                            <img src={frame} />
                            <p className="horizontal-content"> These platforms deliver superior revenue and attachment rates in areas of non-airline ancillary categories such as</p>
                        </div>
                        <div className="available-platform" style={{ marginLeft: "24px" }}>
                            <div className="text-tabs">
                                <span className="tabs-button">Hotel</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Car Rental</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Transfers</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Experiences</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Insurance</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Rail travel</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Cruise</span>
                            </div>
                        </div>
                        <p className="horizontal-content" style={{ marginLeft: "24px" }}>& airport ancillaries such as</p>
                        <div className="available-platform" style={{ marginLeft: "24px" }}>
                            <div className="text-tabs">
                                <span className="tabs-button">Fast track</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Lounges</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Car Parking</span>
                            </div>
                        </div>
                    </div>
                    <div className="horizontal-item platform_image mobileT">
                        <img src={bookingDigital} alt="Description of the image" width={688} height={668} />
                    </div>
                </div>
                <div className="horizontal-view">
                    <div className="horizontal-item platform_image">
                        <img src={bookingFulfilment} alt="Description of the image" width={688} height={668} />
                    </div>
                    <div className="horizontal-item">
                        <div className="travel_saas_img">
                            <img src={icon} width={56} height={56} />
                            <h3 className="horizontal-title space-bt">Booking & fulfilment platforms</h3></div>
                        <div className="horizontal-paragraph">
                            <img src={frame} />
                            <p className="horizontal-content"> zen3 provide booking & fulfilment platforms to intermediaries, including dynamic packaging solutions for.</p>
                        </div>
                        <div className="available-platform" style={{ marginLeft: "24px" }}>
                            <div className="text-tabs">
                                <span className="tabs-button">Travel Agents</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">OTA’s</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">TMC’s</span>
                            </div>
                            <div className="text-tabs">
                                <span className="tabs-button">Tour Operators / Wholesalers</span>
                            </div>
                        </div>
                        <div className="horizontal-paragraph" style={{ marginTop: "30px" }}>
                            <img src={frame} />
                            <p className="horizontal-content">We offer content sources and aggregation to supplement any client specific content. This drives further revenue opportunities for the principal. For example, for an airline, more non-air ancillary revenue and a greater portion of air ticket bookings which have attachment of non-air ancillaries.</p>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <h1 className="heading-title">Case Study</h1>
                </div>
                <div className="horizontal-view mobile_vesrion">
                    <div className="horizontal-item booking-airline mobileV">
                        <h3 className="horizontal-title">Round the World Booking Platform for a leading Airline Alliance</h3>
                        <div className="horizontal-paragraph">
                            <img src={frame} /> <p className="horizontal-content"> A mobile and desktop platform solution was developed by zen3 for a world leading airline alliance.</p>
                        </div>
                        <div className="horizontal-paragraph">
                            <img src={frame} /> <p className="horizontal-content"> The platform enables travellers to book multi alliance member flights to build, price and book round the world itineraries.</p>
                        </div>
                        <div className="horizontal-paragraph">
                            <img src={frame} /><p className="horizontal-content"> Ticketing and fulfilment is undertaken by the principal carrier in the journey saving distribution costs.</p>
                        </div>
                        <div className="horizontal-paragraph">
                            <img src={frame} /><p className="horizontal-content"> The solution continues to be evolved and has been driving increased sales and revenues.</p>
                        </div>
                    </div>
                    <div className="horizontal-item mobileT">
                        <div className="gobal-image">
                            <img src={bookingsAirline} alt="Description of the image" width={560} height={480} />
                        </div>
                    </div>
                </div>

                <div className="Outsourcing-container">
                    <div className="Outsourcing-main-content">
                        <div className="Outsourcing-right-content">
                            <p>Business Process<br /> Outsourcing & Offshoring</p>
                        </div>
                        <Link to="/businessprocess" style={{ textDecoration: "none", color: "white" }}>
                            <div className="Outsourcing-left-content">
                                <p>Learn More <img src={group} /></p>
                                {/* <div className="Outsourcing-line"></div> */}
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="zen3-card">
                    <div className="card">
                        <p className="heading">
                            zen3 is your trusted solutions and business partner to take your
                            travel business to the next level
                        </p>
                        <a href="mailto:enquiries@zen3.com" style={{ textDecoration: 'none' }}>
                            <div className="btn-uparrow w-fit-content text">
                                <div className="">Connect Now</div>
                                <img src={uparrowIcon} />
                            </div>
                        </a>
                    </div>
                </div>

            </div>
            <div className="main-footer">
                <Footer />
            </div>
        </div>
    );
}

export default Bookings;