import React, { useEffect } from "react";
import "../../css/bookings.css";
import Footer from "../Footer";
import yellowUpArrow from "../../asserts/svgs/yellowUpArrow.svg";
import yellowMic from "../../asserts/svgs/yellowMic.svg";
import yellowFrame from "../../asserts/svgs/yellowFrame.svg";
import yellowOperations from "../../asserts/svgs/yellowOperations.svg";
import group from "../../asserts/svgs/Group 159.svg";
import uparrowIcon from "../../asserts/svgs/uparrow.svg";
import digitalHeader from "../../asserts/Images/digital-header.png";
import digitalMarket from "../../asserts/Images/digital-marketing.png";
import digitalOperations from "../../asserts/Images/digital-operations.png";
import digitalTechnology from "../../asserts/Images/digital-technology.png";
import digitalGlobal from "../../asserts/Images/digital-global.png";
import { Link } from 'react-router-dom';

const Digital = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="background-digital">
            <div className="top-content" style={{maxWidth:"1440px", margin:"0 auto"}}>
                <div className="breadcrumb">
                    <ul>
                        <li><Link to="/" style={{ textDecoration: "none", color: "white" }}>Home</Link></li>
                        <li>Digital Transformation</li>
                    </ul>
                </div>
                <div className="content mobile_vesrion">
                    <h1 className="heading-title">digital transformation</h1>
                    <p className="paragraph mobileV">
                        Digital transformation is a broad spectrum and often means different things in different companies. zen3 offers expertise in a range of marketing and operational areas with a focus on the travel industry and its ecosystem.

                        In all practice areas zen3 offers the latest capability incorporating both Machine Learning and Artificial Intelligence.
                    </p>
                    <div className="image-container mobileT">
                        <img src={digitalHeader} alt="Description of the image" width={720} height={400} />
                    </div>
                </div>
                </div>
            </div>
            <div className="main-container">
                <div className="title-container">
                    <h2 className="title-text">zen3 services include</h2>
                </div>
                <div className="horizontal-view marketing-arias mobile_vesrion">
                    <div className="horizontal-item mobileV">
                        <div className="travel_saas_img">
                            <img src={yellowMic} width={56} height={56} />
                            <h3 className="horizontal-title space-bt">Marketing</h3></div>
                        <div className="horizontal-paragraph">
                            <p className="horizontal-content">
                                <img src={yellowUpArrow} /> All areas of marketing, including </p>
                        </div>
                        <div className="available-platform" style={{marginLeft:"24px"}}>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Digital Marketing</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">SEO</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Social Media Marketing</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">E-mail marketing optimisation</span>
                            </div>
                        </div>
                    </div>
                    <div className="horizontal-item mobileT">
                        <img src={digitalMarket} alt="Description of the image" width={688} height={668} />
                    </div>
                </div>
                <div className="horizontal-view">
                    <div className="horizontal-item">
                        <img src={digitalOperations} alt="Description of the image" width={688} height={668} />
                    </div>
                    <div className="horizontal-item">
                        <div className="travel_saas_img">
                            <img src={yellowOperations} width={56} height={56} />
                            <h3 className="horizontal-title space-bt">Operations</h3></div>
                        <div className="horizontal-paragraph">
                            <p className="horizontal-content">
                                <img src={yellowUpArrow} />All areas of business processing, including </p>
                        </div>
                        <div className="available-platform" style={{marginLeft:"24px"}}>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Accounting</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">After Call Work</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Ticketing</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Transaction Processing</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Debit Memo Management</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Call & contact centre services</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="horizontal-view mobile_vesrion">
                    <div className="horizontal-item mobileV">
                        <div className="travel_saas_img">
                            <img src={yellowFrame} width={56} height={56} />
                            <h3 className="horizontal-title space-bt">Technology</h3></div>
                        <div className="horizontal-paragraph">
                            <img src={yellowUpArrow} />
                            <p className="horizontal-content">Technology automation services</p>
                        </div>
                        <div className="available-platform" style={{marginLeft:"24px"}}>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Software security and scale auditing</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">AI & ML</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Robotic process automation</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Software consultancy</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Migration of legacy software</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Web development</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Technical support</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Booking platform development</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Cyber Security</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Software solution design</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Solution Deployment and Management</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">PMO</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Developing and Prototyping new solutions and services</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Software teams ensuring IP for your platforms</span>
                            </div>
                            <div className="digital-text-tabs digitl-boxes-color">
                                <span className="tabs-button">Cyber security training & testing</span>
                            </div>
                        </div>
                    </div>

                    <div className="horizontal-item platform_image mobileT">
                        <img src={digitalTechnology} alt="Description of the image" width={688} height={668} />
                    </div>
                </div>
                <div className="content">
                    <h1 className="heading-title">Case Study</h1>
                </div>
                <div className="horizontal-view mobile_vesrion">
                    <div className="horizontal-item booking-airline mobileV">
                        <h3 className="horizontal-title">Online Booking platform for a large global travel group:</h3>
                        <div className="horizontal-paragraph">
                            <img src={yellowUpArrow} />
                            <p className="horizontal-content">zen3 has developed and delivered a latest next generation booking platform covering tour and holiday packages.</p>
                        </div>
                        <div className="horizontal-paragraph">
                            <img src={yellowUpArrow} />
                            <p className="horizontal-content">The platform was developed, delivered and deployed end to end in a record timeline with customer acknowledged perfect quality standards.</p>
                        </div>
                        <div className="horizontal-paragraph">
                            <img src={yellowUpArrow} />
                            <p className="horizontal-content">Involvement of client personnel in this project to provide user experience designs meeting their company standards and business use cases and zen3 ensured the perfect execution of the project end-to-end.</p>
                        </div>
                    </div>
                    <div className="horizontal-item mobileT">
                        <div className="global-travel-image-card">
                            <img src={digitalGlobal} width={560} height={480} />
                        </div>
                    </div>
                </div>
                <div className="Outsourcing-container">
                    <div className="Outsourcing-main-content">
                        <div className="Outsourcing-right-content">
                            <p>Transformational Booking &<br /> Fulfilment Platforms</p>
                        </div>
                        <Link to="/bookings" style={{ textDecoration: "none", color: "white" }}>
                            <div className="Outsourcing-left-content">
                                <p>Learn More <img src={group} /></p>
                                {/* <div className="Outsourcing-line"></div> */}
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="zen3-card">
                    <div className="card">
                        <p className="heading">
                            zen3 is your trusted solutions and business partner to take your
                            travel business to the next level
                        </p>
                        <a href="mailto:enquiries@zen3.com" style={{ textDecoration: 'none' }}>
                            <div className="btn-uparrow w-fit-content text">
                                <div className="">Connect Now</div>
                                <img src={uparrowIcon} />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="main-footer">
                <Footer />
            </div>
        </div>
    )
}

export default Digital;