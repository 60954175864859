import React, { useEffect } from "react";
import "../../css/bookings.css";
import Footer from "../Footer";
import callCenter from "../../asserts/svgs/call-center-icon.svg";
import offshore from "../../asserts/svgs/offshore centres.svg";
import nearshore from "../../asserts/svgs/nearshore centres.svg";
import greenFreame from "../../asserts/svgs/green Frame 2608707.svg";
import transactionProcess from "../../asserts/svgs/transactionProcess.svg";
import group from "../../asserts/svgs/Group 159.svg";
import uparrowIcon from "../../asserts/svgs/uparrow.svg";
import businessAccount from "../../asserts/Images/business-accounting.png";
import businessHeader from "../../asserts/Images/business-header.png";
import businessCallCenter from "../../asserts/Images/business-callCenter.png";
import businessTransaction from "../../asserts/Images/business-transcations.png";
import { Link } from 'react-router-dom';

const Businessprocess = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="background-business">
            <div className="top-content" style={{maxWidth:"1440px", margin:"0 auto"}}>
                <div className="breadcrumb">
                    <ul>
                        <li><Link to="/" style={{ textDecoration: "none", color: "white" }}>Home</Link></li>
                        <li>Business Process Outsourcing & Offshoring</li>
                    </ul>
                </div>
                <div className="content mobile_vesrion">
                    <h1 className="heading-title"> business process outsourcing <br />& offshoring</h1>
                    <p className="paragraph mobileV">
                        zen3 is highly experienced in providing both BPO and offshoring solutions to a range of travel and travel related companies.  We specialise in “Lift and Shift” in areas of technology, operations and transforming performance via lower cost arbitrage before designing and delivering further automation and business process improvements.
                    </p>
                    <div className="image-container mobileT">
                        <img src={businessHeader} alt="Description of the image" width={720} height={400} />
                    </div>
                </div>
                </div>
            </div>
            <div className="main-container">
                <div className="title-container">
                    <h2 className="title-text">zen3 services include</h2>
                </div>
                <div className="horizontal-view mobile_vesrion">
                    <div className="horizontal-item mobileV">
                        <div className="travel_saas_img">
                            <img src={callCenter} width={56} height={56} />
                            <h3 className="horizontal-title space-bt">Call Centres</h3></div>
                        <div className="offshore">
                            <img src={offshore} alt="Offshore Icon" height={72} />
                            <p className="shore-text">Offshore Solutions</p>
                        </div>
                        <br />
                        <div className="offshore">
                            <img src={nearshore} alt="Near-Shore Icon" height={72} />
                            <p className="shore-text">Near-Shore Solutions</p>
                        </div>
                    </div>
                    <div className="horizontal-item platform_image mobileT" >
                        <img src={businessCallCenter} alt="Description of the image" width={688} height={668} />
                    </div>
                </div>
                <div className="horizontal-view">
                    <div className="horizontal-item platform_image">
                        <img src={businessTransaction} alt="Description of the image" width={688} height={668} />
                    </div>
                    <div className="horizontal-item">
                        <div className="travel_saas_img">
                            <img src={transactionProcess} width={56} height={56} />
                            <h3 className="horizontal-title space-bt">Transaction process cost reduction</h3>
                        </div>
                        <div className="horizontal-paragraph">
                            <img src={greenFreame} /><p className="horizontal-content">zen3 solutions focus on the reduction of transaction process cost via offshoring and greater automation using the latest best practices in technology and Human and Artificial Intelligence.</p>
                        </div>
                        <div className="horizontal-paragraph">
                            <img src={greenFreame} /><p className="horizontal-content"> Our offshoring options can be near shore, or far shore and can include multiple language solutions. Our objective is to offer the savings of lower cost labour whilst delivering excellence in the customer experience.</p>
                        </div>
                        <div className="horizontal-paragraph">
                            <img src={greenFreame} /><p className="horizontal-content"> zen3’s approach is to offer a proven model to “Lift and Shift” your operations, deliver steady state offshore performance and then further automate previously manual services via technology in both front and back office environments.</p>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <h1 className="heading-title">Case Study</h1>
                </div>
                <div className="horizontal-view mobile_vesrion">
                    <div className="horizontal-item booking-airline mobileV">
                        <h3 className="horizontal-title">Accounting services for a large European travel network</h3>
                        <div className="horizontal-paragraph">
                            <img src={greenFreame} /><p className="horizontal-content">zen3 provides end to end accounting services for a large travel agency network.</p>
                        </div>
                        <div className="horizontal-paragraph">
                            <img src={greenFreame} /><p className="horizontal-content"> The benefits to the customer of outsourcing and offshoring this solution has realised over 50% savings compared to historical costs, and in addition enabled the customer's management team to focus on new initiatives within their core business.</p>
                        </div>
                    </div>
                    <div className="horizontal-item gobal-image accounting-image-card mobileT">
                        <img src={businessAccount} width={560} height={480} />
                    </div>
                </div>
                <div className="Outsourcing-container">
                    <div className="Outsourcing-main-content">
                        <div className="Outsourcing-right-content">
                            <p> Digital Transformation</p>
                        </div>
                        <Link to="/digital" style={{ textDecoration: "none", color: "white" }}>
                            <div className="Outsourcing-left-content">

                                <p>Learn More <img src={group} /></p>
                                {/* <div className="Outsourcing-line"></div> */}
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="zen3-card">
                    <div className="card">
                        <p className="heading">
                            zen3 is your trusted solutions and business partner to take your
                            travel business to the next level
                        </p>
                        <a href="mailto:enquiries@zen3.com" style={{ textDecoration: 'none' }}>
                            <div className="btn-uparrow w-fit-content text">
                                <div className="">Connect Now</div>
                                <img src={uparrowIcon} />
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="main-footer">
                <Footer />
            </div>
        </div>
    )
}

export default Businessprocess;