import React from "react";
import "../css/footer.css";
import phoneIcon from "../asserts/svgs/phoneIcon.svg";
import zen3Logo from "../asserts/svgs/logo.svg";
import coptRightIcon from "../asserts/svgs/copyrighticon.svg";
import linkedInIcon from "../asserts/svgs/linkedinIcon.svg";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footerContent">
          <div className="footerColumn">

            <Link to="/bookings" style={{ textDecoration: 'none', color: "#001233" }}>
              <h4>Transformational<br /> Booking & Fulfilment</h4>
            </Link>
            <ul>
              <li>Travel SaaS</li>
              <li>Digital Dynamic Packaging</li>
              <li>Booking & fulfilment platforms</li>
            </ul>
          </div>
          <div className="footerColumn">
            <Link to="/businessprocess" style={{ textDecoration: 'none', color: "#001233" }}>
              <h4>BPO & Outsourcing</h4>
            </Link>
            <ul>
              <li>Call Centres</li>
              <li>Transaction process cost reduction</li>
            </ul>
          </div>
          <div className="footerColumn">
            <Link to="/digital" style={{ textDecoration: 'none', color: "#001233" }}>
              <h4>Digital Transformation</h4>
            </Link>
            <ul>
              <li>Marketing</li>
              <li>Operations</li>
              <li>technology</li>
            </ul>
          </div>
          <div className="footerColumn">
            <Link to="/enquiries" style={{ textDecoration: 'none', color: "#001233" }}>
              <h4>Contact</h4>
            </Link>
            <ul>
              <li><div className="contact"><img src={phoneIcon} alt="phone" /> +44 (0) 203 711 0300</div></li>
              <li>Locations</li>
              <li>London, United kingdom</li>
              <li>hyderabad, india</li>
            </ul>
          </div>
        </div>
        <div className="footerBottom">
          <div className="footer-copy">
            <img src={coptRightIcon} alt="copyRightIcon" />
            <div>2024 ZEN3UK ALL RIGHTS RESERVED</div>
          </div>
          <div className="footer-copy">
            <div>FOLLOW US ON</div>
            <div className="copy-right-img">
              <Link to="https://www.linkedin.com/feed/" target="_blank">
                 <img src={linkedInIcon} alt="copyRightIcon" />
            </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
